import type { ContentType, PID } from '~/types'

interface ConfigState {
  leaderboardPID?: PID
  contentId?: string
  contentType?: ContentType
}

export const useConfigStore = defineStore('config', {
  state: (): ConfigState => ({
    leaderboardPID: 'leaderboard_homepage',
    contentId: undefined,
    contentType: undefined,
  }),
  actions: {
    setState({ leaderboardPID, contentId, contentType }: ConfigState) {
      this.leaderboardPID = leaderboardPID
      this.contentId = contentId
      this.contentType = contentType
    },
    resetState() {
      this.leaderboardPID = undefined
      this.contentId = undefined
      this.contentType = undefined
    },
  },
})
