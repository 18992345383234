<script setup lang="ts">
import { useConfigStore } from '~/store'

/*
  This components fetches and adUrl based on a placement ID, and renders it in an iframe
*/
const props = defineProps<{
  placementId: string
}>()

const config = useConfigStore()

const srcUrl = computedAsync(async () => {
  const placement = await requestPlacementById(props.placementId)
  if (!placement)
    return null

  const result = new URL(placement.adurl)
  config.contentId && result?.searchParams.set('vid', config.contentId)
  config.contentType && result?.searchParams.set('ch', config.contentType)
  return result.href
}, null)
</script>

<template>
  <div v-if="srcUrl" class="relative w-full h-full bg-transparent">
    <iframe
      ref="iframeRef"
      class="absolute top-0 left-0 w-full h-full border-none"
      :src="srcUrl"
      allow="fullscreen; autoplay"
    />
  </div>
</template>
