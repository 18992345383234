import { LRUCache } from 'lru-cache'
import { hash as ohash } from 'ohash'
import type { Placement } from '~/types'

const apiBaseUrl = 'https://api.bt.co/v1/rule/rainberrytv/10'

const promiseCache = new LRUCache<string, any>({
  max: 500,
  ttl: 30 * 1000 * 60, // 10 min
})

const hashKey = 'rainberrytv-ad'

export async function requestPlacements() {
  const hash = ohash(hashKey)
  const state = useState<Placement[] | null>(hash, () => null)

  if (state.value)
    return state.value

  if (!promiseCache.has(hash)) {
    try {
      const res = await $fetch(apiBaseUrl) as { placements: Placement[] }

      state.value = res.placements
      promiseCache.set(hash, Promise.resolve(res.placements))
      return res.placements
    }
    catch (error) {
      promiseCache.delete(hash)
      throw createError(error as any)
    }
  }

  return promiseCache.get(hash) as Placement[]
}

export async function requestPlacementsWithNoCache() {
  try {
    const res = await $fetch(apiBaseUrl) as { placements: Placement[] }
    return res.placements
  }
  catch (error) {
    throw createError(error as any)
  }
}

export async function requestPlacementById(id: string): Promise<Placement | undefined> {
  try {
    // TODO: uncomment in production
    // const placements = await requestPlacements()
    const placements = await requestPlacementsWithNoCache()

    return placements.filter(({ pid }) => pid === id)?.[0]
  }
  catch (error) {
    throw createError(error as any)
  }
}
