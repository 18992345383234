<script lang="ts" setup>
import { useConfigStore } from '~/store'

const config = useConfigStore()
</script>

<template>
  <header
    class="h-16 lg:h-[100px] w-full bg-[#111111bf] flex items-center px-4 fixed top-0 z-10"
  >
    <ClientOnly>
      <DropDownMenu />
    </ClientOnly>

    <NuxtLink to="/" title="Home" class="h-1/3">
      <NuxtImg src="/logo.png" alt="Home" class="h-full" />
    </NuxtLink>

    <section v-if="config.leaderboardPID" class="w-[728px] h-[90px] hidden xl:block absolute left-1/2 -translate-x-1/2">
      <!-- key for forceUpdate -->
      <ContentPlacement :key="`${config.contentType}-${config.contentId}`" :placement-id="config.leaderboardPID" />
    </section>
  </header>
</template>

<style scoped></style>
