import { default as aboutfc0k7JgPlfMeta } from "/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/about.vue?macro=true";
import { default as _91channel_type_93dZwu464UuhMeta } from "/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/channel/[channel_type].vue?macro=true";
import { default as indexTAq27qVBfAMeta } from "/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/index.vue?macro=true";
import { default as privacy_45policycYrZ74NwtgMeta } from "/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/privacy-policy.vue?macro=true";
import { default as _91fetchId_93GYD9ddAeInMeta } from "/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/watch/[channel]/[fetchId].vue?macro=true";
export default [
  {
    name: aboutfc0k7JgPlfMeta?.name ?? "about",
    path: aboutfc0k7JgPlfMeta?.path ?? "/about",
    meta: aboutfc0k7JgPlfMeta || {},
    alias: aboutfc0k7JgPlfMeta?.alias || [],
    redirect: aboutfc0k7JgPlfMeta?.redirect,
    component: () => import("/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91channel_type_93dZwu464UuhMeta?.name ?? "channel-channel_type",
    path: _91channel_type_93dZwu464UuhMeta?.path ?? "/channel/:channel_type()",
    meta: _91channel_type_93dZwu464UuhMeta || {},
    alias: _91channel_type_93dZwu464UuhMeta?.alias || [],
    redirect: _91channel_type_93dZwu464UuhMeta?.redirect,
    component: () => import("/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/channel/[channel_type].vue").then(m => m.default || m)
  },
  {
    name: indexTAq27qVBfAMeta?.name ?? "index",
    path: indexTAq27qVBfAMeta?.path ?? "/",
    meta: indexTAq27qVBfAMeta || {},
    alias: indexTAq27qVBfAMeta?.alias || [],
    redirect: indexTAq27qVBfAMeta?.redirect,
    component: () => import("/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycYrZ74NwtgMeta?.name ?? "privacy-policy",
    path: privacy_45policycYrZ74NwtgMeta?.path ?? "/privacy-policy",
    meta: privacy_45policycYrZ74NwtgMeta || {},
    alias: privacy_45policycYrZ74NwtgMeta?.alias || [],
    redirect: privacy_45policycYrZ74NwtgMeta?.redirect,
    component: () => import("/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91fetchId_93GYD9ddAeInMeta?.name ?? "watch-channel-fetchId",
    path: _91fetchId_93GYD9ddAeInMeta?.path ?? "/watch/:channel()/:fetchId()",
    meta: _91fetchId_93GYD9ddAeInMeta || {},
    alias: _91fetchId_93GYD9ddAeInMeta?.alias || [],
    redirect: _91fetchId_93GYD9ddAeInMeta?.redirect,
    component: () => import("/home/prow/go/src/github.com/bittorrent/ads-content-website/pages/watch/[channel]/[fetchId].vue").then(m => m.default || m)
  }
]